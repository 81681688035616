import { makeStyles } from "@material-ui/core";
import useHooks from "../hooks/useHooks";
import Logs from "./Logs";
import ButtonTabs from "./ButtonTabs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100% - 6px)",
    marginLeft: 3,
  },
  container: {
    width: "90%",
    marginLeft: "5%",
    display: "flex",
    marginTop: "30px",
    //marginTop: "130px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  selectors: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 24,
    paddingTop: 8,
  },
  fullWidth: {
    flex: "0 0 100%",
  },
  element: {
    marginTop: "-220px",
  },
}));

export default function Tables({ refs, corporateActionsRef }) {
  const classes = useStyles();
  const { useLogs } = useHooks();
  const data = useLogs();

  const setCalculationStatus = (status) => {
    console.log("status2", status);
    data.refetch();
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <ButtonTabs setCalculationStatus={setCalculationStatus} />
        <Logs data={data} />
      </div>
    </div>
  );
}
