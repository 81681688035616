import { createContext, useContext } from "react";
import useProducts from "./useProducts";
import useRouting from "./useRouting";
import usePermissions from "./usePermissions";
import useLogs from "./useLogs";
import useReportSettings from "./useReportSettings";
import useProductInfo from "./useProductInfo";
import useUser from "./useUser";
import useReportRun from "./useReportRun";
import useReportLogs from "./useReportLogs";

export const HookContext = createContext({
  useProducts,
  useLogs,
  useRouting,
  usePermissions,
  useReportSettings,
  useProductInfo,
  useUser,
  useReportRun,
  useReportLogs,
});

export default function useHooks() {
  return useContext(HookContext);
}
