import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import useHooks from "../hooks/useHooks";
import { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#productpicker": {
      // doesn't work yet
    },
    ".MuiInputBase-root": {
      height: "100%",
      display: "inline-block",
      paddingTop: "20px",
      paddingLeft: "10px",
    },
    ".MuiFormControl-root": {
      height: "202px",
      marginTop: "5px",
      border: "1px solid #ccc",
      backgroundColor: "#fff",
    },
    ".MuiFormLabel-root": {
      paddingTop: "20px",
      paddingLeft: "20px",
    },
    ".MuiInputBase-input": {
      display: "inline-block",
      width: "100% !important",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      borderTop: "1px solid #ccc !important",
      paddingBottom: "15px !important",
      textIndent: "20px",
      paddingTop: "10px !important",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
    ".MuiAutocomplete-endAdornment": {
      top: "auto !important",
      right: "0",
      bottom: "0px",
      position: "absolute",
      paddingRight: "20px !important",
      paddingBottom: "10px !important",
    },
  },

  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  inputRoot: {
    height: "100%",
    display: "inline-block",
    paddingTop: "30px",
    paddingLeft: "10px",
  },
  formControlRoot: {
    height: "202px",
    border: "1px solid #ccc",
  },
  inputLabelRoot: {
    paddingTop: "20px",
    paddingLeft: "20px",
  },
  /*overrides: {
     MuiAutocomplete: {
      inputRoot: {
        height: "100%",
        display: "inline-block",
        paddingTop: "30px",
        paddingLeft: "10px",
      },
    },
    Mui: {
      root: {
        height: "320px",
        border: "1px solid #ccc",
      },
      inputLabelRoot: {
        paddingTop: "20px",
        paddingLeft: "20px",
      },
    },
  },*/
}));

export default function ProductPicker({ toggleProducts }) {
  const classes = useStyles();
  const { useProducts } = useHooks();
  const { data: products } = useProducts();
  const [currentProducts, setCurrentProducts] = useState([]);

  useEffect(() => {
    toggleProducts(currentProducts);
  }, [currentProducts, toggleProducts]);

  return (
    <div id="productPicker" className={classes.root}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={products}
        getOptionLabel={(option) => option.NAME}
        onChange={(event, newCurrentProducts) => {
          newCurrentProducts = newCurrentProducts.map((product) => {
            return { id: product.ID, isin: product.ISIN, name: product.NAME };
          });
          setCurrentProducts(newCurrentProducts);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Products"
            placeholder="Select a product"
          />
        )}
      />
    </div>
  );
}
