import { gql, useQuery } from "@apollo/client";

const GET_PRODUCT_BY_ISIN = gql`
  query getProductInfo($isin: String!) {
    getProductInfo(isin: $isin) {
      ID
      ISIN
      NAME
    }
  }
`;

export default function useProductInfo(isin) {
  const { loading, error, data, refetch } = useQuery(GET_PRODUCT_BY_ISIN, {
    variables: { isin },
    skip: !isin,
  });

  const refetchData = () => {
    refetch();
  };

  if (loading || error) {
    return { loading, error, data: [], refetch: refetchData };
  }

  return {
    loading,
    error,
    data: data?.getProductInfo,
    refetch: refetchData,
  };
}
