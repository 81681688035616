import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreSharp";
import ExpandLessIcon from "@material-ui/icons/ExpandLessSharp";
import { useState } from "react";
import clsx from "clsx";
import DynamicIcon from "./DynamicIcon";

const useStyles = makeStyles((theme) => ({
  hiddenTransition: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hidden: {
    opacity: 0,
  },
  itemCollapseIcon: {
    width: 20,
    height: 20,
  },
  nested: {
    backgroundColor: "rgb(46, 54, 57)",
  },
  open: {
    backgroundColor: "rgb(46, 54, 57)",
  },
  item: {
    paddingLeft: 50,
    paddingRight: 50,
    transition: theme.transitions.create("padding-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  itemNarrow: {
    paddingLeft: 40,
  },
  itemText: {
    fontWeight: 800,
  },
  itemSelected: {
    color: ({ menuHighlightColor }) => menuHighlightColor,
  },
  itemTextNested: {
    fontWeight: 500,
  },
  divider: {
    backgroundColor: "rgb(46, 54, 57)",
  },
}));

export default function FirstLevelMenuItem({
  name,
  id,
  menuIcon,
  menuIconRenderer,
  children,
  showDivider = true,
  minimized,
  loadedModuleId,
  setMenuOpen,
}) {
  const menuHighlightColor = "rgb(82, 173, 213)";
  const classes = useStyles({ menuHighlightColor });
  const [_open, setOpen] = useState(false);

  const selected = loadedModuleId === id;

  const open = !minimized && _open;

  const itemClick = () => {
    if (children) {
      setMenuOpen(true);
      setOpen(!open);
    } else {
      window.location.href = `/${id}`;
    }
  };

  return (
    <>
      {showDivider && <Divider classes={{ root: classes.divider }} />}
      <ListItem
        button
        onClick={itemClick}
        className={clsx({
          [classes.item]: !minimized,
          [classes.itemNarrow]: minimized,
          [classes.open]: open,
          [classes.itemSelected]: selected,
        })}
      >
        <ListItemIcon
          classes={{
            root: clsx({
              [classes.itemSelected]: selected,
            }),
          }}
        >
          {menuIconRenderer ? (
            menuIconRenderer()
          ) : (
            <DynamicIcon name={menuIcon} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={name}
          classes={{
            root: clsx(classes.hiddenTransition, {
              [classes.hidden]: minimized,
            }),
            primary: classes.itemText,
          }}
        />
        {children &&
          (open ? (
            <ExpandLessIcon
              className={clsx(
                classes.itemCollapseIcon,
                classes.hiddenTransition,
                {
                  [classes.hidden]: minimized,
                }
              )}
            />
          ) : (
            <ExpandMoreIcon
              className={clsx(
                classes.itemCollapseIcon,
                classes.hiddenTransition,
                {
                  [classes.hidden]: minimized,
                }
              )}
            />
          ))}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}
