import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    height: "100%",
    border: "none",
    overflow: "hidden",
    background:
      "linear-gradient(-55deg, rgb(242, 242, 242), rgba(1, 207, 238, 0.2)) white",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    alignContent: "stretch",
  },
  expanded: {
    left: 370,
    width: "calc(100% - 370px)",
  },
  collapsed: {
    left: 130,
    width: "calc(100% - 130px)",
  },
  transition: {
    transition: theme.transitions.create(["left", "width", "height", "top"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function Content({ expand, children, customStyles = "" }) {
  const background = {
    background:
      "linear-gradient(-55deg, rgb(242, 242, 242), rgba(1, 207, 238, 0.2)) white",
  };
  let classes = useStyles({ background });
  console.log("customStyles", customStyles);
  return (
    <div
      style={customStyles ? customStyles : null}
      className={clsx(classes.transition, classes.root, classes.background, {
        [classes.expanded]: !expand,
        [classes.collapsed]: expand,
      })}
    >
      {children}
    </div>
  );
}
