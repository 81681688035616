import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default function useUser() {
  const initialState = {
    "https://chartered-investment.com/verified_email": "",
    "https://chartered-investment.com/podio_item_id": 0,
    "https://chartered-investment.com/lixx_podio_item_id": 0,
    "https://chartered-investment.com/name": "",
    "https://chartered-investment.com/roles": [],
    iss: "",
    sub: "",
    aud: [],
    iat: 0,
    exp: 0,
    azp: "",
    scope: "",
    permissions: [],
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (state["https://chartered-investment.com/podio_item_id"] === 0) {
      if (process.env.NODE_ENV === "development") {
        const mockUser = {
          "https://chartered-investment.com/verified_email": "test@email.com",
          "https://chartered-investment.com/podio_item_id": 1,
          "https://chartered-investment.com/lixx_podio_item_id": 1,
          "https://chartered-investment.com/name": "Test User",
          "https://chartered-investment.com/roles": ["CIG employee"],
          iss: "mock_iss",
          sub: "mock_sub",
          aud: ["mock_aud"],
          iat: Date.now() / 1000,
          exp: Date.now() / 1000 + 60 * 60, // expires in 1 hour
          azp: "mock_azp",
          scope: "mock_scope",
          permissions: ["reporting:edit", "quotation:edit"],
          userId: 1,
          lixxUserId: 1,
          hasCIGRole: false,
        };
        setState(mockUser);
      } else {
        try {
          const token = Cookies.get("access-token");
          const user = jwt_decode(token);

          setState({
            ...user,
            userId: user["https://chartered-investment.com/podio_item_id"],
            lixxUserId:
              user["https://chartered-investment.com/lixx_podio_item_id"],
            hasCIGRole:
              user["https://chartered-investment.com/roles"].includes(
                "CIG employee"
              ),
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }, [state]);

  return state;
}
