import { gql, useQuery } from "@apollo/client";

export default function useLogs() {
  const { loading, error, data, refetch } = useQuery(
    gql`
      query {
        getLogs {
          ID
          STEPNAME
          FORPRODUCT
          NOTIFICATION
          TRIGGEREDBY
          INSERTTIME
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  if (loading || error) {
    return { loading, error, data: [], refetch };
  }

  return { loading, error, data: data.getLogs, refetch };
}
