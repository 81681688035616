import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

export default function useReportLogs(initialExecutionId) {
  const [executionId, setExecutionId] = useState(initialExecutionId);
  const [logsData, setLogsData] = useState([]);

  const GET_REPORT_LOGS = gql`
    query GetReportLogs($executionId: String!) {
      getReportLogs(executionId: $executionId) {
        status
        statusText
        logs
      }
    }
  `;

  const { loading, error, data, refetch } = useQuery(GET_REPORT_LOGS, {
    variables: { executionId },
    skip: !executionId,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && data.getReportLogs) {
      setLogsData(data.getReportLogs); // Update logsData state when data changes
    }
  }, [data]);

  const refetchData = (newExecutionId) => {
    refetch({ executionId: newExecutionId });
    setExecutionId(newExecutionId);
  };

  const clearData = () => {
    setLogsData([]); // Clear logs data
  };

  if (loading || error) {
    return { loading, error, data: [], refetch: refetchData, clearData };
  }

  return {
    loading,
    error,
    data: logsData,
    refetch: refetchData,
    clearData,
  };
}
