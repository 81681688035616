import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginTop: "15px",
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  svg: {
    color: ({ logoTextColor }) => logoTextColor,
  },
};

const CardLoader = ({ size }) => {
  return (
    <div style={styles.container}>
      <CircularProgress size={size ? size : 33} style={styles.svg} />
    </div>
  );
};

export default CardLoader;
