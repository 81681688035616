import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  hiddenTransition: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  textLogo: {
    position: "absolute",
    width: 110,
    height: 185,
    color: ({ logoTextColor }) => logoTextColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(-90deg)",
    fontWeight: 800,
  },
}));

export default function TextLogo({ text, className }) {
  const logoTextColor = "rgb(82, 173, 213)";
  const classes = useStyles({ logoTextColor });
  return (
    <Typography
      className={clsx(classes.textLogo, classes.hiddenTransition, className)}
    >
      {text.split("\n").map((v, i) => (
        <>
          {i !== 0 && <br />}
          {v}
        </>
      ))}
    </Typography>
  );
}
