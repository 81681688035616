import { gql, useQuery } from "@apollo/client";

export default function useReportRun() {
  const { loading, error, data, refetch } = useQuery(gql`
    query {
      getLatestRun {
        status
        statusText
        run {
          refId
          type
          status
          startDate
          endDate
          isCustomDateActive
          selectedIsins
          emailsSent
          reportsGenerated
          sftpUploads
          startTimestamp
          endTimestamp
          downloadLinksHtml
          errors
        }
      }
    }
  `);

  const refetchData = () => {
    refetch();
  };

  if (loading || error) {
    return { loading, error, data: [], refetch: refetchData };
  }

  return {
    loading,
    error,
    data: data.getLatestRun,
    refetch: refetchData,
  };
}
