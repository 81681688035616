import React from "react";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Warning from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Done";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "40px",
    flex: "0 0 100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  logs: {},
  list: {
    height: "300px",
    overflowY: "scroll",
    border: "1px solid #aaaaaa",
    marginBottom: "30px",
  },
  listItem: {
    height: "30px",
  },
  seperator: {
    marginTop: "0px !important",
  },
  "@global": {
    "#logs .MuiList-root": {
      marginTop: "10px !important",
      backgroundColor: "#fff",
    },
  },
}));

const Logs = ({ data }) => {
  const { data: logs } = data;

  const classes = useStyles();
  return (
    <div id="logs" className={`${classes.root}`}>
      <label
        style={{
          fontFamily: "Helvetica",
          fontSize: "13px",
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        Logs:
      </label>
      <List className={`${classes.logs} ${classes.list}`}>
        {logs.map((logEntry, index) => (
          <>
            <ListItem classname={classes.listItem}>
              <ListItemAvatar>
                <Avatar>
                  {logEntry.NOTIFICATION.includes("Success") ? (
                    <DoneIcon />
                  ) : (
                    <Warning />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {logEntry.NOTIFICATION}: {logEntry.STEPNAME} by{" "}
                    {logEntry.TRIGGEREDBY}
                  </>
                }
                secondary={logEntry.INSERTTIME}
              />
            </ListItem>
            <Divider
              variant="inset"
              component="li"
              classname={classes.seperator}
            />
          </>
        ))}
      </List>
    </div>
  );
};
export default React.memo(Logs);
