import { makeStyles } from "@material-ui/core";
import CustomAvatar from "./CustomAvatar";
import SecondLevelMenuItem from "./SecondLevelMenuItem";
import FirstLevelMenuItem from "./FirstLevelMenuItem";

const useStyles = makeStyles((theme) => ({
  userMenu: {
    marginBottom: 32,
  },
}));

export default function UserMenu({ minimized, setMenuOpen }) {
  let name = "",
    picture = "";
  const classes = useStyles();

  return (
    <div className={classes.userMenu}>
      <FirstLevelMenuItem
        name={name}
        id="user"
        menuIconRenderer={() => <CustomAvatar picture={picture} name={name} />}
        showDivider={false}
        minimized={minimized}
        setMenuOpen={setMenuOpen}
      >
        <SecondLevelMenuItem
          name="Logout"
          id="logout"
          showDivider={false}
          minimized={minimized}
        />
      </FirstLevelMenuItem>
    </div>
  );
}
