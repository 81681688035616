import gql from "graphql-tag";

export const updateNotionalMutation = gql`
  mutation UpdateNotional(
    $newAmount: Float!
    $productIsin: String!
    $date: String!
    $navId: String!
  ) {
    updateNotional(
      newAmount: $newAmount
      productIsin: $productIsin
      date: $date
      navId: $navId
    ) {
      void
    }
  }
`;

export const updateQuantityMutation = gql`
  mutation UpdateQuantity($quantity: Float!, $id: String!) {
    updateQuantity(quantity: $quantity, id: $id) {
      void
    }
  }
`;

export const updateParticipationMutation = gql`
  mutation UpdateParticipation($participation: Boolean!, $id: String!) {
    updateParticipation(participation: $participation, id: $id) {
      void
    }
  }
`;

export const calculatePriceMutation = gql`
  mutation calculatePrice($productNames: [String]) {
    calculatePrice(productNames: $productNames) {
      status
      statusText
    }
  }
`;

export const generateComponentsMutation = gql`
  mutation generateComponents($productNames: [ProductInput]) {
    generateComponents(productNames: $productNames) {
      status
      statusText
    }
  }
`;

export const executePriceMutation = gql`
  mutation executePrice(
    $productNames: [ProductInput]
    $step: String
    $type: String
  ) {
    executePrice(productNames: $productNames, step: $step, type: $type) {
      status
      statusText
    }
  }
`;

export const compartmentCalculationMutation = gql`
  mutation compartmentCalculation($productNames: [String]) {
    compartmentCalculation(productNames: $productNames) {
      status
      statusText
    }
  }
`;

export const addReportSettingMutation = gql`
  mutation AddReportSetting($input: ReportSettingInput!) {
    addReportSetting(reportSetting: $input) {
      UPDATED
    }
  }
`;

export const updateReportSettingMutation = gql`
  mutation UpdateReportSetting($input: ReportSettingInput!) {
    updateReportSetting(reportSetting: $input) {
      UPDATED
    }
  }
`;

export const reportExecuteMutation = gql`
  mutation ExecuteReport($input: ReportExecutionInput) {
    executeReport(executionInput: $input) {
      status
      statusText
    }
  }
`;

export const reportCancelMutation = gql`
  mutation CancelReport($input: String!) {
    cancelReport(executionId: $input) {
      status
      statusText
    }
  }
`;

export const sendConfirmationEmailMutation = gql`
  mutation SendConfirmationEmail($input: String!) {
    sendConfirmationEmail(email: $input) {
      status
      statusText
    }
  }
`;

export const deleteReportSettingMutation = gql`
  mutation DeleteReportSetting($input: Int!) {
    deleteReportSetting(id: $input) {
      status
      statusText
    }
  }
`;
