import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import TextLogo from "./TextLogo";

export const useStyles = makeStyles((theme) => ({
  hiddenTransition: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hidden: {
    opacity: 0,
  },
  logoContainer: {
    position: "relative",
    width: 350,
    height: 185,
  },
  logo: {
    position: "absolute",
    width: 350,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 75,
  },
}));

export default function PortalLogo({ open }) {
  let logoText = " Test ";
  const classes = useStyles({});

  return (
    <div className={classes.logoContainer}>
      <TextLogo
        text={logoText}
        className={clsx({
          [classes.hidden]: open,
        })}
      />
    </div>
  );
}
