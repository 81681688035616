import AdjustSharp from "@material-ui/icons/AdjustSharp";
import AppsSharp from "@material-ui/icons/AppsSharp";
import AccountBalanceWalletSharp from "@material-ui/icons/AccountBalanceWalletSharp";
import PolicySharp from "@material-ui/icons/PolicySharp";
import TrendingUpSharp from "@material-ui/icons/TrendingUpSharp";
import PieChartSharp from "@material-ui/icons/PieChartSharp";
import List from "@material-ui/icons/List";
import Instifolio from "./icons/Instifolio";

export default function DynamicIcon({ name, ...others }) {
  switch (name) {
    case "AppsSharp":
      return <AppsSharp {...others} />;
    case "AccountBalanceWalletSharp":
      return <AccountBalanceWalletSharp {...others} />;
    case "PolicySharp":
      return <PolicySharp {...others} />;
    case "TrendingUpSharp":
      return <TrendingUpSharp {...others} />;
    case "PieChartSharp":
      return <PieChartSharp {...others} />;
    case "List":
      return <List {...others} />;
    case "Instifolio":
      return <Instifolio {...others} />;
    default:
      return <AdjustSharp {...others} />;
  }
}
