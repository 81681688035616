import { gql, useQuery } from "@apollo/client";

export default function useReportSettings() {
  const { loading, error, data, refetch } = useQuery(
    gql`
      query {
        getReportsSettings {
          ID
          ISIN
          PRODUCTNAME
          REPORT_NAME_SUFFIX
          INCLUDE
          SEND_EMAIL
          EMAIL_FORMAT
          INCLUDE_UNSETTLED_POSITIONS
          HIDE_NON_ACCUMULATING
          HISTORY
          EMAIL_RECIPIENTS
          SEND_FTP
          FTP_FORMAT
          POSITIONS_OPTION
          FREQUENCY_OPTION
          FREQUENCY_VALUE
          RUN_PROFILE
          UPDATED
          UPDATEDBY
        }
      }
    `,
    {
      onError: (error) => {
        console.error("GraphQL error: ", error.message);
      },
    }
  );
  const defaultReportSettingData = [
    {
      ID: 1,
      ISIN: "NA",
      PRODUCTNAME: "Default Product",
      REPORT_NAME_SUFFIX: "_default",
      INCLUDE: false,
      SEND_EMAIL: false,
      EMAIL_FORMAT: "CSV",
      INCLUDE_UNSETTLED_POSITIONS: false,
      HIDE_NON_ACCUMULATING: false,
      HISTORY: 10,
      EMAIL_RECIPIENTS: ["example@example.com"],
      SEND_FTP: false,
      FTP_FORMAT: "CSV",
      POSITIONS_OPTION: "Full",
      FREQUENCY_OPTION: "Daily",
      FREQUENCY_VALUE: "",
      RUN_PROFILE: "Official",
      UPDATED: new Date().toISOString(),
      UPDATEDBY: "System",
    },
  ];

  const refetchData = () => {
    refetch().catch((error) => {
      console.error("Refetch error: ", error.message);
      // Handle refetch error
    });
  };

  if (loading) {
    console.log("Loading...");
    return {
      loading,
      error: null,
      data: defaultReportSettingData,
      refetch: refetchData,
    };
  }

  if (error) {
    console.error("GraphQL error: ", error.message);
    return {
      loading: false,
      error,
      data: defaultReportSettingData,
      refetch: refetchData,
    };
  }

  return {
    loading,
    error,
    data: data?.getReportsSettings || [],
    refetch: refetchData,
  };
}
