import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import useFullscreenable from "./hooks/useFullscreenable";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

const useStyles = makeStyles((theme) => ({
  root: {
    height: ({ headerHeight }) => headerHeight,
    width: "100%",
    paddingLeft: 64,
    paddingRight: 64,
  },
  transition: {
    transition: theme.transitions.create(["left", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  image: {
    backgroundImage: "url('/header_images/cig.jpg')",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    height: ({ headerHeight }) => headerHeight - 4,
    display: "flex",
    alignItems: "center",
    paddingLeft: 54,
    color: ({ headerTextColor }) => headerTextColor,
  },
  line: {
    backgroundColor: ({ headerBarColor }) => headerBarColor,
    width: "100%",
    height: 4,
  },
  welcomeText: {
    fontWeight: 700,
  },
  fullscreenButton: {
    position: "absolute",
    top: 8,
    right: 8 + 64,
  },
}));

export function Header({ loadedModule }) {
  const { name } = "test";
  const [fullScreen, setFullScreen] = useFullscreenable();

  const headerHeight = 122;
  const classes = useStyles({ headerHeight });

  const textVariant = "h4";

  return (
    <div
      style={{ paddingLeft: "0px", position: "fixed", zIndex: "3" }}
      className={clsx(classes.transition, classes.root)}
    >
      <div className={clsx(classes.transition, classes.image)}>
        <div className={classes.fullscreenButton}>
          <Tooltip title="Toggle Fullscreen" aria-label="Toggle Fullscreen">
            <IconButton onClick={() => setFullScreen(!fullScreen)}>
              {!fullScreen && <FullscreenIcon />}
            </IconButton>
          </Tooltip>
        </div>
        <Typography
          variant={textVariant}
          classes={{ [textVariant]: classes.welcomeText }}
        >
          {`Willkommen, ${name}`}
        </Typography>
      </div>
      <div className={classes.line}></div>
    </div>
  );
}
