import Cookies from "js-cookie";

const getPermissionsFromToken = (jwtToken) => {
  if (!jwtToken) {
    return [];
  }

  let jwtData = jwtToken.split(".")[1];
  let decodedJwtJsonData = window.atob(jwtData);
  let decodedJwtData = JSON.parse(decodedJwtJsonData);

  return Array.from(decodedJwtData.permissions || []);
};

export default function usePermissions() {
  const token = Cookies.get("access-token");

  console.log("token", token);
  const permissions = getPermissionsFromToken(token);

  const hasPermission = (permission) =>
    (permissions || []).includes(permission);

  return { permissions, hasPermission };
}
