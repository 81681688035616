import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Checkbox, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import useHooks from "../hooks/useHooks";
//import useWindowSize from "../hooks/useWindowSize";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditSettings from "./EditSettings";
import ReportingController from "./ReportingController.js";

import CardLoader from "../components/CardLoader";

import ErrorBoundary from "../components/ErrorBoundary.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100%)",
    marginLeft: 10,
    "& .MuiDataGrid-main": {
      backgroundColor: "#fff",
      minHeight: "500px",
    },
    "& .MuiDataGrid-columnHeader.theme-header, .MuiDataGrid-columnHeaderCheckbox":
      {
        backgroundColor: "#F5F5F5",
      },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
      {
        outline: "none !important",
      },
    "& .MuiDataGrid-cellContent": {
      fontSize: "0.75rem",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 200px)", 
    minHeight: "700px", // Minimum height for the entire container
  },
  gridContainer: {
    flexGrow: 1,
    minHeight: "400px",
    overflow: "auto",
  },
  controllerContainer: {
    marginTop: "20px",
  },
  buttonRoot: {
    "&.MuiButtonBase-root": {
      border: "1px solid #1976d2",
      color: "#1976d2",
    },
  },
  menuItemRoot: {
    "& .MuiMenuItem-root": {
      display: "grid",
      fontStyle: "italic",
    },
  },
  selectors: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 24,
    paddingTop: 8,
  },
  fullWidth: {
    flex: "0 0 100%",
  },
  element: {
    marginTop: "-220px",
  },
  fileTypeLabel: {
    borderRadius: "8px",
    backgroundColor: "#e0f7fa",
    padding: "8px 16px 8px 16px",
    textAlign: "center",
    fontSize: "0.75rem",
  },
}));

const RenderActions = (props) => {
  const { onEdit } = props;
  const handleEditClick = () => {
    onEdit(props.row);
  };
  return (
    <>
      <IconButton onClick={handleEditClick} title="Edit Report Setting">
        <EditIcon />
      </IconButton>
    </>
  );
};

const AddHandlerContext = React.createContext(null);

function GridToolbar({ setFilterButtonEl }) {
  const handleAdd = React.useContext(AddHandlerContext);

  return (
    <Grid container>
      <Grid item sm={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <Box display="flex">
            <GridToolbarFilterButton color="info" ref={setFilterButtonEl} />
            <GridToolbarColumnsButton color="info" />
          </Box>
          <Box display="flex" justifyContent="center" flexGrow={1}>
            {/* Center Section */}
            <Tooltip title="Add New Report Setting" arrow>
              <IconButton
                onClick={handleAdd}
                color="primary"
                aria-label="Create"
              >
                <AddCircleOutlineIcon style={{ fontSize: 32 }} />
                <Typography sx={{ p: 1 }} variant="body1">
                  Add Compartment
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <GridToolbarQuickFilter
              quickFilterParser={(searchInput) =>
                searchInput
                  .split(",")
                  .map((value) => value.trim())
                  .filter((value) => value !== "")
              }
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
GridToolbar.propTypes = {
  setFilterButtonEl: PropTypes.func.isRequired,
};

const ReportAdmin = () => {
  const renderCellWithToolTip = React.useCallback(
    (params) => (
      <Tooltip title={params.value ? params.value.toString() : ""}>
        <span style={{ fontSize: "0.75rem" }}>{params.value}</span>
      </Tooltip>
    ),
    []
  );

  const columns = [
    {
      field: "PRODUCTNAME",
      headerName: "Compartment",
      minWidth: 210,
      flex: 3,
      editable: false,
      disableColumnMenu: true,
      filterable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      hideable: false,
    },
    {
      field: "ISIN",
      headerName: "ISIN",
      minWidth: 120,
      flex: 2,
      editable: false,
      disableColumnMenu: true,
      filterable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      hideable: true,
      hide: true,
    },
    {
      field: "REPORT_NAME_SUFFIX",
      headerName: "Report Name Suffix",
      minWidth: 200,
      flex: 3,
      editable: false,
      disableColumnMenu: true,
      filterable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      hideable: true,
      hide: true,
    },
    {
      field: "INCLUDE",
      headerName: "Active",
      minWidth: 80,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      renderCell: (params) => {
        return <Checkbox checked={params.value || false} disabled />;
      },
    },
    {
      field: "Official",
      headerName: "Official",
      minWidth: 80,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      valueGetter: (params) => params.row.RUN_PROFILE,
      renderCell: (params) => {
        return (
          <Checkbox checked={params.value?.includes("Official")} disabled />
        );
      },
    },
    {
      field: "PreRun",
      headerName: "PreRun",
      minWidth: 80,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      valueGetter: (params) => params.row.RUN_PROFILE,
      renderCell: (params) => {
        return <Checkbox checked={params.value?.includes("PreRun")} disabled />;
      },
    },
    {
      field: "SEND_EMAIL",
      headerName: "Email",
      minWidth: 80,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      renderCell: (params) => {
        return <Checkbox checked={params.value || false} disabled />;
      },
    },
    {
      field: "POSITIONS_OPTION",
      headerName: "Pos.",
      minWidth: 70,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      filterable: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      hideable: true,
      hide: true,
    },
    {
      field: "FREQUENCY_OPTION",
      headerName: "Sched.",
      minWidth: 70,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
    },
    {
      field: "EMAIL_FORMAT",
      headerName: "Format",
      minWidth: 100,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      renderCell: (params) => (
        <div className={classes.fileTypeLabel}>{params.value}</div>
      ),
    },
    {
      field: "HIDE_NON_ACCUMULATING",
      headerName: "Hide Fees",
      minWidth: 90,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      renderCell: (params) => {
        return <Checkbox checked={params.value || false} disabled />;
      },
      hideable: true,
      hide: true,
    },
    {
      field: "INCLUDE_UNSETTLED_POSITIONS",
      headerName: "Include Unsettled Positions",
      minWidth: 90,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      renderCell: (params) => {
        return <Checkbox checked={params.value || false} disabled />;
      },
    },
    {
      field: "HISTORY",
      headerName: "History",
      minWidth: 80,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      type: "number",
    },
    {
      field: "EMAIL_RECIPIENTS",
      headerName: "Recipients",
      minWidth: 210,
      flex: 4,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      headerClassName: "theme-header",
      renderCell: renderCellWithToolTip,
    },
    {
      field: "SEND_FTP",
      headerName: "FTP",
      minWidth: 90,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
    },
    {
      field: "FTP_FORMAT",
      headerName: "Format",
      minWidth: 100,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
      renderCell: (params) => (
        <div className={classes.fileTypeLabel}>{params.value}</div>
      ),
    },
    {
      field: "UPDATED",
      headerName: "Updated",
      minWidth: 95,
      flex: 2,
      editable: false,
      disableColumnMenu: true,
      filterable: false,
      headerAlign: "center",
      align: "center",
      type: "datetime",
      headerClassName: "theme-header",
    },
    {
      field: "UPDATEDBY",
      headerName: "Updated By",
      minWidth: 100,
      flex: 2,
      editable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      headerClassName: "theme-header",
    },
    {
      field: "ID",
      headerName: "Edit",
      minWidth: 30,
      renderCell: (props) => <RenderActions {...props} onEdit={handleEdit} />,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      hideable: false,
      type: "number",
      headerClassName: "theme-header",
      getApplyQuickFilterFn: undefined,
    },
  ];
  const calculateInitialHeight = () => window.innerHeight - 355;
  const [gridHeight, setGridHeight] = useState(calculateInitialHeight);
  const boxRef = useRef(null);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originalEmailRecipients, setOriginalEmailRecipients] = useState("");

  const { useReportSettings } = useHooks();
  const { loading, data: ReportSettingsData } = useReportSettings();

  let isLoading = loading;

  const updateHeight = () => {
    if (boxRef.current) {
      setGridHeight(boxRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    // Set initial height
    updateHeight();

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    // Avoiding unnecessary updates by comparing new and old selected row data
    const newSelectedRowData =
      ReportSettingsData.filter !== undefined
        ? ReportSettingsData.filter((row) => selectedRows.includes(row.ID))
        : [];

    if (
      JSON.stringify(newSelectedRowData) !== JSON.stringify(selectedRowData)
    ) {
      setSelectedRowData(newSelectedRowData);
    }

    // Optionally, you can log the data here to verify the behavior
  }, [selectedRows, ReportSettingsData, selectedRowData]);

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel);
  };

  const handleEdit = (rowData) => {
    setRowData(rowData);
    setOriginalEmailRecipients(rowData.EMAIL_RECIPIENTS || "");
    setDrawerOpen(true);
    setEditMode(true);
  };

  const handleAdd = () => {
    setRowData({
      RUN_PROFILE: "Official",
      EMAIL_FORMAT: "XLS",
      FTP_FORMAT: "XLS",
      FREQUENCY_OPTION: "Daily",
      POSITIONS_OPTION: "Combined",
      HISTORY: 500,
    }); // default values
    setOriginalEmailRecipients("");
    setDrawerOpen(true);
    setEditMode(false);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const classes = useStyles();
  //const screenSize = useWindowSize();

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  return isLoading ? (
    <CardLoader />
  ) : (
    <AddHandlerContext.Provider value={handleAdd}>
      <Box className={classes.container}>
        <Box
          className={classes.gridContainer}
          ref={boxRef}
          sx={{
            width: "100%",
            "& .theme-header": {
              backgroundColor: "rgba(255, 7, 0, 0.55)",
            },
            "& .super-app-theme--green": {
              backgroundColor: "#c7f8c7",
            },
            "& .super-app-theme--red": {
              backgroundColor: "#ff000021",
            },
          }}
        >
          <ErrorBoundary>
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 100, page: 0 },
                },
                columns: {
                  columnVisibilityModel: {
                    ISIN: false,
                    REPORT_NAME_SUFFIX: false,
                    POSITIONS_OPTION: false,
                    INCLUDE_UNSETTLED_POSITIONS: false,
                  },
                },
              }}
              rowHeight={40}
              columnBuffer={35}
              getRowId={(row) => row.ID}
              rows={ReportSettingsData}
              columns={columns}
              checkboxSelection
              disableRowSelectionOnClick
              sx={{
                minHeight: "500px",
                height: "100%", // This ensures the DataGrid takes up all available space
              }}
              className={classes.root}
              onRowSelectionModelChange={handleSelectionModelChange}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                panel: {
                  anchorEl: filterButtonEl,
                },
                toolbar: {
                  setFilterButtonEl,
                },
                columnsPanel: {
                  sx: {
                    "& .MuiDataGrid-panelFooter": {
                      display: "none",
                    },
                    "& .MuiSwitch-switchBase": {
                      position: "absolute",
                    },
                  },
                },
              }}
            />
          </ErrorBoundary>
        </Box>
        <Box className={classes.controllerContainer}>
          <ReportingController
            selectedRowData={selectedRowData}
            gridBoxRef={boxRef}
          />
        </Box>
      </Box>

      <EditSettings
        open={drawerOpen}
        onClose={handleCloseDrawer}
        rowData={rowData}
        editMode={editMode}
        gridHeight={gridHeight}
        originalEmailRecipients={originalEmailRecipients}
      />
    </AddHandlerContext.Provider>
  );
};

export default ReportAdmin;
