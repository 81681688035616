import React, { useState } from "react";
import { AppBar, Tabs, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

const getModulePath = (moduleName) =>
  process.env.NODE_ENV === "development" ? "" : `${moduleName}/`;

const tabGroups = [
  [
    { path: "overview", label: "Overview" },
    { path: "reportAdmin", label: "Report Administration" },
    { path: "", label: "" },
    { path: "", label: "" },
    { path: "", label: "" },
  ],
];

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: "none !important",
  border: "none !important",
  background: "none !important",
  backgroundColor: "transparent !important",
  "& .MuiPaper-root": {
    boxShadow: "none !important",
    border: "none !important",
    background: "none !important",
    backgroundColor: "transparent !important",
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#EC721D",
  },
  "& .MuiTab-root": {
    color: theme.palette.text.primary,
  },
}));

const NavBar = () => {
  const moduleName = getModulePath("quotation");
  const location = useLocation();
  const initialTabValues = tabGroups.map((group) => {
    return group.findIndex((tab) => {
      const fullPath = `/${moduleName}${tab.path}`;
      //console.log("fullPath", fullPath);
      return location.pathname === fullPath;
    });
  });

  const [tabValues, setTabValues] = useState(initialTabValues);

  const handleChange = (groupIndex) => (_, newValue) => {
    setTabValues((oldValues) => {
      const newValues = [...oldValues];
      newValues[groupIndex] = newValue;
      return newValues;
    });
  };

  return (
    <StyledAppBar position="static">
      {tabGroups.map((group, groupIndex) => (
        <StyledTabs
          key={groupIndex}
          value={tabValues[groupIndex] > -1 ? tabValues[groupIndex] : false}
          onChange={handleChange(groupIndex)}
          aria-label="Navigation"
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          {group.map((tab, tabIndex) => {
            const fullPath = `/${moduleName}${tab.path}`;
            return (
              <Tab
                key={tabIndex}
                href={fullPath}
                label={tab.label}
                to={fullPath}
                disabled={!tab.label}
              ></Tab>
            );
          })}
        </StyledTabs>
      ))}
    </StyledAppBar>
  );
};

export default NavBar;
