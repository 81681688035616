import { Avatar, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: -8,
    backgroundColor: "White",
  },
  avatarText: {
    fontWeight: 800,
  },
}));

export default function CustomAvatar({ name, picture }) {
  const classes = useStyles();

  const isDefaultPicture = !picture || picture.includes("cdn.auth0.com");

  if (isDefaultPicture) {
    const short = name.replaceAll(/[^A-Z]/g, "");
    return (
      <Avatar className={classes.avatar}>
        <Typography variant="body1" className={classes.avatarText}>
          {short}
        </Typography>
      </Avatar>
    );
  } else {
    return <Avatar src={picture} className={classes.avatar} />;
  }
}
