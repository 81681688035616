import { gql, useQuery } from "@apollo/client";

export default function useProducts() {
  const { loading, error, data } = useQuery(gql`
    query {
      getProducts {
        ID
        ISIN
        NAME
      }
    }
  `);

  if (loading || error) {
    return { loading, error, data: [] };
  }

  return { loading, error, data: data.getProducts };
}
