import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Overview from "./overview/Overview";
import ReportAdmin from "./reportAdmin";
import { React, useEffect } from "react";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import Menu from "./components/Menu";
import { Header } from "./components/Header";
import Content from "./components/Content";
import { useSessionStorage } from "react-use";
import NavBar from "./components/NavBar";
import { AuthLink } from "./components/AuthLink";
import useHooks from "./hooks/useHooks";
import "./styles.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(82, 173, 213)",
    },
    secondary: {
      main: "rgb(255, 115, 0)",
    },
  },
  typography: {
    fontFamily: "Helvetica, sans-serif",
    fontSize: 14,
  },
});

function App() {
  const [menuOpen, setMenuOpen] = useSessionStorage(
    "menuOpen",
    window.screen.width > 1024
  );
  let moduleName = "quotation/";
  if (process.env.NODE_ENV === "development") {
    moduleName = "";
  }

  const { useUser } = useHooks();
  const user = useUser();
  //console.log("user", user);
  const authLink = new AuthLink(user);
  const httpLink = new HttpLink({ uri: "/modules/quotation/graphql" });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  // This is a hack to fix the height and width of the report admin module
  useEffect(() => {
    const wrapped = document.querySelector("#enableFullscreen") || document.querySelector("#disableFullscreen");
    const reportAdminElement = document.querySelector("#reportAdminParent");

    const firstParent = reportAdminElement && reportAdminElement.parentElement;

    const thirdParent =
      firstParent &&
      reportAdminElement.parentElement.parentElement &&
      reportAdminElement.parentElement.parentElement.parentElement;

    if (firstParent && wrapped) {
      firstParent.style.overflow = "hidden";
    }

    if (thirdParent) {
      thirdParent.style.height = null;
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <NavBar />
          <Switch>
            <Route
              path={`/${moduleName}overview`}
              render={() => (
                <div>
                  <Overview />
                </div>
              )}
            />
            <Route
              path={`/${moduleName}reportadmin`}
              render={() => (
                <div id="reportAdminParent">
                  <ReportAdmin />
                </div>
              )}
            />
            <Route
              path={`/wrapper/${moduleName}overview`}
              render={() => (
                <div>
                  <Menu open={menuOpen} setOpen={setMenuOpen} />
                  <Content expand={!menuOpen} customStyles={{ height: "auto" }}>
                    <Header />
                    <Overview />
                  </Content>
                </div>
              )}
            />
            <Route path={`/${moduleName}`}>
              <Redirect to={`/${moduleName}overview`} />
            </Route>
          </Switch>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
