import { makeStyles, Drawer } from "@material-ui/core";
import clsx from "clsx";
import NavigateBeforeSharpIcon from "@material-ui/icons/NavigateBeforeSharp";
import NavigateNextSharpIcon from "@material-ui/icons/NavigateNextSharp";

const drawerWidth = 350;
const drawerWidthClosed = 110;

export const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: drawerWidthClosed,
  },
  drawerTrigger: {
    height: "100%",
    width: 20,
    backgroundColor: "rgb(46, 54, 57)",
    borderLeft: "rgb(79, 85, 88) 1px solid",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    overflowX: "hidden",
  },
  menu: {
    height: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "rgb(37, 43, 47)",
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  navIcon: {
    marginTop: -10,
    width: 20,
    height: 20,
  },
}));

export default function MenuDrawer({ open, setOpen, children }) {
  const classes = useStyles({});

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.root),
        }}
      >
        <div
          className={clsx(classes.menu, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
        >
          {children}
        </div>
        <div className={classes.drawerTrigger} onClick={() => setOpen(!open)}>
          {open ? (
            <NavigateBeforeSharpIcon
              htmlColor={"#808080"}
              classes={{ root: classes.navIcon }}
            />
          ) : (
            <NavigateNextSharpIcon
              htmlColor={"#808080"}
              classes={{ root: classes.navIcon }}
            />
          )}
        </div>
      </Drawer>
    </>
  );
}
