import { createSvgIcon } from "@material-ui/core";

export default createSvgIcon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 18.4 17.3"
  >
    <path
      class="st0"
      d="M8.6,17.3c-0.6,0-1.3-0.3-1.7-0.7l-4.7-4.7l2.5-2.5l0.2,0.1c0.1,0.1,0.6,0,0.8,0l0.2-0.1L6,9.5l1.3,1.3
	l-0.1,0.2C7.1,11.1,7,11.2,7,11.4c0,0.6,0.5,1,1.1,1c0.6,0,1.1-0.5,1.1-1.1c0-0.1,0-0.2,0-0.3l-0.1-0.2l2.2-2.2l0.2,0.1
	c0.1,0.1,0.3,0.1,0.4,0.1c0.6,0,1-0.5,1-1.1c0-0.1,0-0.2-0.1-0.3l-0.1-0.2l1.9-1.8L16.4,7c0.5,0.5,0.7,1.1,0.7,1.7
	c0,0.6-0.3,1.3-0.7,1.7l-6.1,6.1C9.9,17,9.3,17.3,8.6,17.3z M3,11.8l4.3,4.3c0.7,0.7,1.9,0.7,2.6,0L16,10c0.3-0.3,0.5-0.8,0.5-1.3
	c0-0.5-0.2-0.9-0.5-1.3l-1.2-1.2l-1.3,1.2c0,0.1,0,0.2,0,0.3c0,0.9-0.7,1.7-1.6,1.7c-0.2,0-0.3,0-0.5-0.1l-1.6,1.6
	c0,0.1,0,0.2,0,0.3c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.6c0-0.2,0-0.4,0.1-0.5l-0.8-0.8c-0.3,0.1-0.6,0.1-0.9,0L3,11.8z
	 M1.9,11.5l-1.1-1.1C0.4,10,0.1,9.4,0.1,8.8c0-0.6,0.3-1.3,0.7-1.7L6.9,1C7.8,0,9.4,0,10.3,1l4.1,4.1l-1.9,1.8l-0.2-0.1
	c-0.7-0.3-1.5,0.3-1.5,0.9c0,0.3,0,0.4,0.1,0.5l0.1,0.2l-2.2,2.2l-0.2-0.2c-0.3-0.2-0.7-0.3-1.1-0.1l-0.2,0.1L6.1,9.1l0.1-0.2
	c0.1-0.2,0.1-0.3,0.1-0.4c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1c0,0.2,0,0.3,0.1,0.4l0.1,0.2L1.9,11.5z M8.6,0.9C8.1,0.9,7.7,1,7.3,1.4
	L1.2,7.5C0.9,7.8,0.7,8.3,0.7,8.8S0.9,9.7,1.2,10l0.7,0.6l1.8-1.8c0-0.2-0.1-0.3-0.1-0.5c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6
	c0,0.2,0,0.4-0.1,0.5l0.8,0.8C8,9.6,8.5,9.7,8.8,9.9l1.5-1.5c-0.1-0.2-0.1-0.4-0.1-0.6c0-1,1-1.9,2.1-1.6L13.5,5L9.9,1.4
	C9.5,1,9.1,0.9,8.6,0.9z"
    />
    <path
      class="st0"
      d="M16.3,5.4c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C18.4,4.5,17.5,5.4,16.3,5.4z M16.3,2.7
	c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6C16.9,3,16.7,2.7,16.3,2.7z"
    />
  </svg>,
  "Instifolio"
);
