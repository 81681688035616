import { createContext, useContext, useState } from "react";

export const Context = createContext([false, () => {}]);

export function FullscreenProvider({ children }) {
  const value = useState(false);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default function useFullscreenable() {
  return useContext(Context);
}
