import { useHistory, useRouteMatch } from "react-router-dom";

const useRouting = () => {
  const { push } = useHistory();
  //const match = useRouteMatch("/quotation/:section/:date/:product?");
  const match = useRouteMatch("/quotation/:section/:date?");
  const { product, date, section } = (match && match.params) || {};

  const setPath = ({ product, date, section }) =>
    push(
      product
        ? `/quotation/${section}/${date}/${product}`
        : date
        ? `/quotation/${section}/${date}`
        : `/quotation/${section}`
    );

  const updateProduct = (newProduct) =>
    setPath({
      product: newProduct,
      date,
      section,
    });
  const updateDate = (newDate) => {
    console.log("ölaksdfjas");
    setPath({
      product,
      date: newDate.toFormat("yyyy-LL-dd"),
      section,
    });
  };
  const updateSection = (newSection) =>
    setPath({
      product,
      date,
      section: newSection,
    });
  const updateSectionAndProduct = (newSection, newProduct) =>
    setPath({
      product: newProduct,
      date,
      section: newSection,
    });

  return {
    parameters: {
      product,
      date,
      section,
    },
    setPath,
    updateProduct,
    updateDate,
    updateSection,
    updateSectionAndProduct,
  };
};
export default useRouting;
