import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Drawer,
  Typography,
  Toolbar,
  IconButton,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Menu,
} from "@material-ui/core";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMutation } from "@apollo/client";
import { reportExecuteMutation, reportCancelMutation } from "../mutations.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@material-ui/icons/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import useHooks from "../hooks/useHooks";

dayjs.extend(utc);
dayjs.extend(weekday);

const isWeekend = (date) => {
  return dayjs(date).weekday() === 0 || dayjs(date).weekday() === 6;
};

const useStyles = makeStyles((theme) => ({
  muiInputStyleOverride: {
    "& .MuiInputBase-input": {
      padding: "16.5px 14px 16.5px 14px !important", // Override padding here
    },
  },
  splitButton: {
    display: "flex",
    height: "100%",
  },
  mainButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    flex: 1,
  },
  dropdownButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: theme.spacing(0, 1),
    minWidth: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formatText: {
    fontSize: "0.75rem",
    lineHeight: 1,
  },
}));

const LogsTable = ({ logs, handleDrawerClose, isLogsLoading }) => (
  <div style={{ margin: "20px" }}>
    {isLogsLoading && (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(245, 245, 245, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <CircularProgress />
      </div>
    )}
    <TableContainer
      component={Paper}
      style={{ maxHeight: 600, overflow: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Logs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs?.map((log, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {log}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <IconButton
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        borderRadius: "50%",
        zIndex: 1001,
      }}
      onClick={handleDrawerClose}
    >
      <CloseIcon />
    </IconButton>
  </div>
);

const ReportingController = ({ selectedRowData, gridBoxRef }) => {
  const classes = useStyles();

  const [isDateActive, setIsDateActive] = useState(false);
  const [format, setFormat] = useState("XLS");
  const [anchorEl, setAnchorEl] = useState(null);

  const startDate = null;
  const [endDate, setEndDate] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // error or success

  const [executingRequest, setExecutingRequest] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  const { useReportRun } = useHooks();
  const { data: reportRunData, refetch: refetchReportRunData } = useReportRun();

  const [executionId, setExecutionId] = useState(null);

  const [hovering, setHovering] = React.useState(false);
  const [isCanceling, setIsCanceling] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [executionType, setExecutionType] = useState(null); // [PreRun, Official]

  const { useReportLogs } = useHooks();
  const {
    loading: isLogsLoading,
    data: reportLogsData,
    refetch: refetchReportLogData,
    clearData: clearReportLogData,
  } = useReportLogs(executionId);

  const [executeReport] = useMutation(reportExecuteMutation);
  const [cancelReport] = useMutation(reportCancelMutation);

  const getReportCount = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      reportRunData.run?.downloadLinksHtml,
      "text/html"
    );
    return doc.querySelectorAll("a").length;
  };

  // Update executionId whenever ReportRunData changes
  useEffect(() => {
    if (reportRunData?.run?.refId) {
      setExecutionId(reportRunData?.run?.refId);
    }
  }, [reportRunData, reportLogsData, refetchReportRunData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Call refetch to get updated ReportRunData
      refetchReportRunData();
      if (
        drawerOpen &&
        executionId &&
        (!reportRunData?.run?.endTimestamp ||
          (reportRunData?.run?.endTimestamp &&
            dayjs
              .utc()
              .diff(dayjs.utc(reportRunData.run.endTimestamp), "minute") <= 1))
      )
        refetchReportLogData(executionId);
    }, 10000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [
    drawerOpen,
    executionId,
    reportLogsData,
    reportRunData,
    refetchReportLogData,
    refetchReportRunData,
  ]);

  useEffect(() => {
    if (isDateActive) {
      // Get today's date
      const now = dayjs();

      // Keep subtracting days until not a weekend
      let date = now.subtract(1, "day");
      while (isWeekend(date)) {
        date = date.subtract(1, "day");
      }

      // Set endDate to last business date
      setEndDate(date);
    }
  }, [isDateActive]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDialogOpen = (message) => {
    setDialogMessage(message);
    setDialogOpen(true);
  };

  const handleFormatMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFormatMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFormatSelect = (selectedFormat) => {
    setFormat(selectedFormat);
    handleFormatMenuClose();
  };

  const handleExecute = async (type, bypass) => {
    setExecutionType(type);
    if (type === "Official" && !bypass && selectedRowData.length === 0) {
      const message =
        "All Active reports marked as Official will be sent to recipients.";
      handleDialogOpen(message);
      return;
    }
    setExecutingRequest(true);
    setIsCanceling(false);
    clearReportLogData();
    const selectedIsins = selectedRowData.map((row) => row.ISIN);
    const ReportExecutionInput = {
      reportType: type,
      isDateActive,
      startDate,
      endDate,
      selectedIsins,
      format,
    };

    try {
      let res = await executeReport({
        variables: { input: ReportExecutionInput },
      });
      if (res.data.executeReport.status !== 200) {
        setSnackbarMessage(
          `Error generating reports: ${res.data.executeReport.statusText}`
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity("success");
        setSnackbarMessage(
          `${ReportExecutionInput.reportType} Reports are generating. RefId = ${res.data.executeReport.statusText}`
        );
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error generating reports: ${error.message}`);
      setSnackbarSeverity("error");
      console.error(error.message);
      setSnackbarOpen(true);
    }
    setExecutingRequest(false);

    refetchReportRunData();
  };

  const handleCancel = async () => {
    setIsCanceling(true); // Set isCanceling to true when handleCancel is called

    if (executionId !== undefined) {
      await cancelReport({
        variables: { input: executionId },
      });
    }
  };

  const isReportLogsAvailable = () => {
    return reportRunData.status === 200 && executionId !== undefined;
  };

  const isDownloaLinksAvailable = () => {
    return (
      (reportRunData.run?.status === 2 || reportRunData.run?.status === 4) && // done = 2 or warning = 4
      reportRunData.run?.downloadLinksHtml !== "" &&
      reportRunData.run?.downloadLinksHtml !== null &&
      reportRunData.run?.endTimestamp &&
      dayjs
        .utc(reportRunData.run?.endTimestamp)
        .isAfter(dayjs.utc().subtract(1, "hour"))
    );
  };

  const handleTabClick = (event, newValue) => {
    newValue = newValue || event.target.innerText;
    setSelectedTab(newValue);

    if (
      (isReportLogsAvailable() === true && newValue === "LOGS") ||
      (isDownloaLinksAvailable() === true &&
        newValue?.includes("DOWNLOAD REPORTS"))
    ) {
      refetchReportLogData(executionId);
      setDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const containerRect = gridBoxRef.current?.getBoundingClientRect();
  const drawerStyle = {
    position: "absolute",
    top: `${containerRect?.top}px`,
    left: `${containerRect?.left}px`,
    height: `${containerRect?.height}px`,
    width: `${containerRect?.width}px`,
    zIndex: 1300,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ position: "relative" }}>
        {(executingRequest || reportRunData.run?.status === 1) && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: "rgba(245, 245, 245, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
            >
              <CircularProgress />
              <Button
                variant="outlined"
                disabled={isCanceling}
                style={{
                  position: "absolute",
                  top: "100%",
                  marginTop: "0px",
                  opacity: 0,
                  transition: "opacity 0.3s",
                  ...(hovering && { opacity: 1 }),
                }}
                onClick={handleCancel}
              >
                {isCanceling ? "Canceling..." : "Cancel Run"}
              </Button>
            </div>
          </div>
        )}
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation Required"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setDialogOpen(false);
                handleExecute(executionType, true); // bypass the confirmation dialog
              }}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ marginTop: 95 }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginLeft: "15px", paddingRight: "30px" }}
        >
          <Grid item xs={12} sm={1} style={{ maxWidth: "50px" }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="50px"
              marginLeft="15px"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDateActive}
                    onChange={() => setIsDateActive(!isDateActive)}
                  />
                }
                label=""
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <DatePicker
              label="Valuation Date"
              value={endDate}
              onChange={setEndDate}
              disabled={!isDateActive}
              timezone="UTC"
              fullWidth
              disableFuture
              className={classes.muiInputStyleOverride}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className={classes.splitButton}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.mainButton}
                onClick={() => handleExecute("Generate")}
                disabled={selectedRowData.length === 0}
              >
                {selectedRowData.length > 0
                  ? `Generate ${selectedRowData.length} Selected Reports`
                  : `Generate Reports`}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.dropdownButton}
                onClick={handleFormatMenuOpen}
                disabled={selectedRowData.length === 0}
              >
                <Typography className={classes.formatText}>{format}</Typography>
                <ArrowDropDownIcon fontSize="small" />
              </Button>
            </div>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleFormatMenuClose}
            >
              <MenuItem onClick={() => handleFormatSelect("CSV")}>CSV</MenuItem>
              <MenuItem onClick={() => handleFormatSelect("XLS")}>XLS</MenuItem>
            </Menu>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip
              title={
                selectedRowData.length === 0
                  ? "All Active reports marked as Official will be send to recipients"
                  : ""
              }
              arrow
            >
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => handleExecute("Official")}
              >
                {selectedRowData.length > 0
                  ? `Send ${selectedRowData.length} Selected Reports`
                  : `Send Reports`}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              disabled={!isDownloaLinksAvailable()}
              onClick={handleTabClick}
            >
              {`Download Reports (${
                isDownloaLinksAvailable() ? getReportCount() : 0
              })`}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={{ position: "relative" }}>
        <AppBar
          position="absolute"
          style={{
            backgroundColor: "transparent",
            padding: "0px",
            margin: "20px 0 0 0",
            zIndex: 1300,
          }}
        >
          <Toolbar style={{ minHeight: "40px", lineHeight: "40px" }}>
            <Typography style={{ flexGrow: 1, textTransform: "uppercase" }}>
              {reportRunData.statusText}
            </Typography>
            <Tabs
              value={false} // This value disables the automatic selection of tabs
              onClick={handleTabClick}
            >
              <Tab label="Logs" disabled={!isReportLogsAvailable()} />
            </Tabs>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={handleDrawerClose}
          style={drawerStyle}
          PaperProps={{ style: { position: "absolute" } }}
          BackdropProps={{ style: { position: "absolute" } }}
          ModalProps={{
            container: gridBoxRef.current,
            style: { position: "absolute" },
          }}
        >
          {selectedTab === "LOGS" && isReportLogsAvailable() && (
            <LogsTable
              logs={reportLogsData.logs}
              handleDrawerClose={handleDrawerClose}
              isLogsLoading={isLogsLoading}
            />
          )}
          {selectedTab?.includes("DOWNLOAD REPORTS") &&
            isDownloaLinksAvailable() && (
              <div
                style={{
                  maxHeight: "650px",
                  overflow: "auto",
                  margin: "20px 10px 20px 10px",
                }}
              >
                <div
                  style={{ padding: "10px", position: "relative" }}
                  dangerouslySetInnerHTML={{
                    __html: reportRunData.run.downloadLinksHtml,
                  }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    borderRadius: "50%",
                  }}
                  onClick={handleDrawerClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
        </Drawer>
      </div>
    </LocalizationProvider>
  );
};

export default ReportingController;
