import { List } from "@material-ui/core";
import UserMenu from "./UserMenu";
import MenuDrawer from "./MenuDrawer";
import PortalLogo from "../Menu/PortalLogo";

export default function Menu({ open, setOpen, loadedModuleId }) {
  return (
    <>
      <MenuDrawer {...{ open, setOpen }}>
        <List component="nav">
          <PortalLogo open={open} />
        </List>

        <UserMenu minimized={!open} setMenuOpen={setOpen} />
      </MenuDrawer>
    </>
  );
}
