import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  hiddenTransition: {
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hidden: {
    opacity: 0,
  },
  itemCollapseIcon: {
    width: 20,
    height: 20,
  },
  nested: {
    backgroundColor: "rgb(46, 54, 57)",
  },
  open: {
    backgroundColor: "rgb(46, 54, 57)",
  },
  item: {
    paddingLeft: 50,
    paddingRight: 50,
    transition: theme.transitions.create("padding-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  itemNarrow: {
    paddingLeft: 40,
  },
  itemText: {
    fontWeight: 800,
  },
  itemSelected: {
    color: ({ menuHighlightColor }) => menuHighlightColor,
  },
  itemTextNested: {
    fontWeight: 500,
  },
  divider: {
    backgroundColor: "rgb(46, 54, 57)",
  },
}));

export default function SecondLevelMenuItem({
  name,
  id,
  showDivider = true,
  minimized,
  loadedModuleId,
  clickHandler,
}) {
  const menuHighlightColor = "rgb(82, 173, 213)";
  const classes = useStyles({ menuHighlightColor });
  const selected = loadedModuleId === id;

  const itemClick =
    clickHandler ||
    (() => {
      window.location.href = `/${id}`;
    });

  return (
    <>
      {showDivider && <Divider classes={{ root: classes.divider }} />}
      <ListItem
        button
        onClick={itemClick}
        className={clsx(
          {
            [classes.item]: !minimized,
            [classes.itemNarrow]: minimized,
            [classes.itemSelected]: selected,
          },
          classes.nested
        )}
      >
        <ListItemIcon
          classes={{
            root: clsx({
              [classes.itemSelected]: selected,
            }),
          }}
        ></ListItemIcon>
        <ListItemText
          primary={name}
          classes={{
            root: clsx(classes.hiddenTransition, {
              [classes.hidden]: minimized,
            }),
            primary: classes.itemTextNested,
          }}
        />
      </ListItem>
    </>
  );
}
