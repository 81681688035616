import { ApolloLink } from "@apollo/client";

export class AuthLink extends ApolloLink {
  constructor(user) {
    super();
    this.user = user;
  }

  request(operation, forward) {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        user: JSON.stringify(this.user),
      },
    });

    // Call the next link in the chain.
    return forward(operation);
  }
}
