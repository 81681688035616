import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import SchoolIcon from "@material-ui/icons/School";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ProductPicker from "./ProductPicker";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { executePriceMutation } from "../mutations";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiBox-root": {
      height: "300px",
      border: "1px solid #ccc !important",
      overflowY: "scroll",
      backgroundColor: "#fff",
    },
    ".MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    ".MuiGrid-root": {
      fontSize: "11px",
    },
    ".MuiTypography-body1": {
      fontSize: "13px !important",
    },
    ".MuiTypography-body2": {
      fontSize: "12px !important",
    },
    ".MuiTab-wrapper": {
      fontSize: "12px",
    },
  },
  root: {
    flexGrow: 1,
    width: "45%",
    paddingLeft: "0px",
  },
  tabpanel: {
    height: "450px",
    border: "1px solid #ccc",
    fontSize: "9px",
  },
  left: {
    textAlign: "left",
  },
  specific_compartments: {
    border: "1px solid #ccc",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginTop: "5px",
  },
  firstElem: {
    marginTop: "25px",
  },
  lastElem: {
    marginBottom: "25px",
  },
  loaderScreen: {
    cursor: "wait",
    position: "fixed",
    height: "100%",
    width: "100%",
    zIndex: 1,
    backgroundColor: "#d1d1d161",
  },
}));

export default function ButtonTabs({ setCalculationStatus }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [executePrice, { loading }] = useMutation(executePriceMutation, {
    fetchPolicy: "no-cache",
  });

  const [currentProducts, setCurrentProducts] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleProducts = async (newCurrentProducts) => {
    console.log(newCurrentProducts);
    setCurrentProducts(newCurrentProducts);
    console.log("currentProducts", currentProducts);
  };

  const triggerCalculation = async (calculationMode, type) => {
    let calculationStatus;

    console.log("calculationMode", calculationMode);
    console.log("currentProducts", currentProducts);

    calculationStatus = await executePrice({
      variables: {
        productNames: currentProducts,
        step: calculationMode,
        type: type,
      },
    });

    setCalculationStatus(calculationStatus);
    console.log("calculationStatus", calculationStatus);
  };

  return (
    <>
      {loading && <div className={classes.loaderScreen} />}
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab
              label="Specific compartments"
              icon={<SchoolIcon />}
              {...a11yProps(0)}
            />
            <Tab
              label="Overall"
              icon={<AllInclusiveIcon />}
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel classes={{ root: classes.tabpanel }} value={value} index={0}>
          <Grid container spacing={3} style={{ paddingTop: "10px" }}>
            <Grid item xs={6} style={{ paddingRight: "20px" }}>
              <ProductPicker toggleProducts={toggleProducts} />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ paddingLeft: "20px", paddingRight: "25px" }}
            >
              <Grid
                container
                spacing={3}
                classes={{ root: classes.specific_compartments }}
              >
                <Grid item xs={2} classes={{ root: clsx(classes.left) }}></Grid>
                <Grid
                  item
                  xs={5}
                  classes={{ root: clsx(classes.left, classes.firstElem) }}
                >
                  5. Components generation
                </Grid>
                <Grid item xs={5} classes={{ root: classes.firstElem }}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      triggerCalculation("generateComponents", "specific")
                    }
                  >
                    Run
                  </Button>
                </Grid>

                <Grid item xs={2} classes={{ root: classes.left }}></Grid>
                <Grid item xs={5} classes={{ root: classes.left }}>
                  6. Pricing calculation
                </Grid>
                <Grid item xs={5}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      triggerCalculation("calculatePrice", "specific")
                    }
                  >
                    Run
                  </Button>
                </Grid>

                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={5}
                  classes={{ root: clsx(classes.left, classes.lastElem) }}
                >
                  7. Inter compartment pricing calculation
                </Grid>
                <Grid item xs={5}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      triggerCalculation(
                        "calculateInterCompartmentPrice",
                        "specific"
                      )
                    }
                  >
                    Run
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel classes={{ root: classes.tabpanel }} value={value} index={1}>
          <Grid
            container
            spacing={3}
            style={{ paddingTop: "20px", paddingBottom: "30px" }}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              1. File Loading (this step may take longer, pls donot click this
              button so offen, auto run at 8:50)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("fileLoading", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              2. Notional Calculating (auto run at 9:05 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() =>
                  triggerCalculation("notionalCalculation", "auto")
                }
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              3. Fee Calculating (auto run at 9:07 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("feeCalculation", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              Upfront Fee Auto Ajustment (auto run at 9:10 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("feeAdjustment", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              4. FEE PI/Invoiced Flg Setting (auto run at 9:12 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("setInvoiceFlags", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              IB Cash GAP Auto Detect (auto run at 9:14)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("IBCashAutoDetect", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              Internal Project Bonds Price Calc (auto run at 9:17am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() =>
                  triggerCalculation("internalProjectBondsCalc", "auto")
                }
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              AVS Project Bonds Price Calc (auto run at 9:20am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() =>
                  triggerCalculation("projectBondsPriceCalc", "auto")
                }
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              5. Components Generating (auto run at 9:38 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("generateComponents", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              6. Pricing Calculating (auto run at 9:48 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => triggerCalculation("calculatePrice", "auto")}
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={5} classes={{ root: classes.left }}>
              7. Inter Compartments Pricing Calculating (auto run at 9:50 am)
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() =>
                  triggerCalculation("calculateInterCompartmentPrice", "auto")
                }
              >
                Run
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
            {/*
          <Grid item xs={3}></Grid>
          <Grid item xs={5} classes={{ root: classes.left }}>
            8. Send Warning Email (pls manual trigger!!!)
          </Grid>
          <Grid item xs={1}>
            <Button size="small" variant="outlined" color="primary">
              Run
            </Button>
          </Grid>
          <Grid item xs={3}></Grid>
            */}
          </Grid>
        </TabPanel>
      </div>
    </>
  );
}
