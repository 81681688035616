import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import {
  Drawer,
  Grid,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Typography,
  Box,
  FormGroup,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@mui/material/Tooltip";
import useHooks from "../hooks/useHooks";
import { useMutation } from "@apollo/client";
import {
  addReportSettingMutation,
  updateReportSettingMutation,
  sendConfirmationEmailMutation,
  deleteReportSettingMutation,
} from "../mutations";

const drawerStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    top: "140px",
    height: "auto",
  },
}));

const EditSettings = ({
  open,
  onClose,
  rowData,
  editMode,
  gridHeight,
  originalEmailRecipients,
}) => {
  const classes = drawerStyles();

  const [data, setData] = useState(rowData || {});
  const [isin, setISIN] = useState(data.ISIN || "");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // error or success
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [errors, setErrors] = useState({});

  const { useProductInfo } = useHooks();
  const { data: ProductInfo, refetch } = useProductInfo(isin);

  const [saving, setSaving] = useState(false);

  const [isDeleteMode, setIsDeleteMode] = useState(false);

  useEffect(() => {
    setData(rowData || {});
  }, [rowData]);

  useEffect(() => {
    if (ProductInfo) {
      setData((prevData) => ({
        ...prevData,
        PRODUCTNAME: ProductInfo.NAME,
        REPORT_NAME_SUFFIX: ProductInfo.ID,
      }));
    }
  }, [ProductInfo]);

  const handleISINBlur = () => {
    refetch();
    setISIN(data.ISIN);
  };

  const handleISINKeyPress = (event) => {
    if (event.key === "Enter") {
      // Prevent form submission
      event.preventDefault();
      refetch();
      setISIN(data.ISIN);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { useReportSettings } = useHooks();
  const { refetch: refetchReportSettings } = useReportSettings();

  const [addReportSetting] = useMutation(addReportSettingMutation);
  const [updateReportSetting] = useMutation(updateReportSettingMutation);
  const [sendConfirmationEmail] = useMutation(sendConfirmationEmailMutation);
  const [deleteReportSetting] = useMutation(deleteReportSettingMutation);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.key === "D") {
        setIsDeleteMode(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift" || event.key === "D") {
        setIsDeleteMode(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this report setting? This action cannot be undone."
      )
    ) {
      setSaving(true);
      try {
        await deleteReportSetting({
          variables: { input: data.ID },
        });
        await refetchReportSettings();
        setSnackbarSeverity("success");
        setSnackbarMessage(
          `Success! The report setting for ${data.ISIN} has been deleted.`
        );
        handleDrawerClose();
      } catch (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(`Error deleting report setting: ${error.message}`);
        console.error("Error deleting report setting:", error.message);
      }
      setSnackbarOpen(true);
      setSaving(false);
    }
    setIsDeleteMode(false);
  };

  const handleScheduleChange = (event) => {
    const newSchedule = event.target.value;
    let defaultValue;
    switch (newSchedule) {
      case "Weekly":
        defaultValue = "Monday";
        break;
      case "Monthly":
        defaultValue = "1"; // Assuming "1" is your default value for Monthly
        break;
      case "Annually":
        defaultValue = "January"; // Assuming "January" is your default value for Annually
        break;
      default:
        defaultValue = null;
    }

    setData({
      ...data,
      FREQUENCY_OPTION: newSchedule,
      FREQUENCY_VALUE: defaultValue,
    });
  };

  // Function to format numbers as ordinal numbers
  const formatOrdinalNumber = (number) => {
    const j = number % 10,
      k = number % 100;
    if (j === 1 && k !== 11) {
      return number + "st";
    }
    if (j === 2 && k !== 12) {
      return number + "nd";
    }
    if (j === 3 && k !== 13) {
      return number + "rd";
    }
    return number + "th";
  };

  const renderDynamicValueArea = (schedule) => {
    const handleChange = (event) => {
      setData({ ...data, FREQUENCY_VALUE: event.target.value });
    };

    switch (schedule) {
      case "Weekly":
        return (
          <FormControl fullWidth>
            <Select value={data.FREQUENCY_VALUE} onChange={handleChange}>
              <MenuItem value="Monday">Monday</MenuItem>
              <MenuItem value="Tuesday">Tuesday</MenuItem>
              <MenuItem value="Wednesday">Wednesday</MenuItem>
              <MenuItem value="Thursday">Thursday</MenuItem>
              <MenuItem value="Friday">Friday</MenuItem>
            </Select>
          </FormControl>
        );

      case "Monthly":
        return (
          <FormControl fullWidth>
            <Select value={data.FREQUENCY_VALUE} onChange={handleChange}>
              {/* Generate menu items for days */}
              {[...Array(30).keys()].map((day) => (
                <MenuItem key={day} value={`${day + 1}`}>
                  {`${formatOrdinalNumber(day + 1)} Business Day`}
                </MenuItem>
              ))}
              <MenuItem value="31">Last Business Day</MenuItem>
            </Select>
          </FormControl>
        );

      case "Annually":
        return (
          <FormControl fullWidth>
            <Select value={data.FREQUENCY_VALUE} onChange={handleChange}>
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((month) => (
                <MenuItem
                  key={month}
                  value={month}
                >{`1st Business Day in ${month}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      default:
        // For 'Daily', 'Bi-Monthly', 'Quarterly', 'Semi-Annually' or any other unhandled cases
        return null;
    }
  };

  const handleSave = async () => {
    setSaving(true);
    let validationErrors = {};

    if (!data.ISIN) {
      validationErrors.ISIN = "ISIN is required";
    }

    if (!data.PRODUCTNAME) {
      validationErrors.PRODUCTNAME = "Existing Product is required";
    }

    if (
      data.SEND_EMAIL &&
      (!data.EMAIL_RECIPIENTS || data.EMAIL_RECIPIENTS.trim() === "")
    ) {
      validationErrors.EMAIL_RECIPIENTS =
        "Email Recipients is required when Send Email is selected";
    } else if (
      data.SEND_EMAIL &&
      !/^(\s*[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*)(;\s*[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*)*$/.test(
        data.EMAIL_RECIPIENTS
      )
    ) {
      validationErrors.EMAIL_RECIPIENTS =
        "Email Recipients must be a semicolon-separated list of valid emails or a single email address";
    }

    if (!data.REPORT_NAME_SUFFIX || data.REPORT_NAME_SUFFIX.trim() === "") {
      validationErrors.REPORT_NAME_SUFFIX = "Report Name Suffix is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSaving(false);
      return;
    }
    try {
      const newData = {
        ...data,
        __typename: undefined,
        PRODUCTNAME: undefined,
        UPDATED: undefined,
        UPDATEDBY: undefined,
      };

      if (
        newData.EMAIL_RECIPIENTS != null &&
        newData.EMAIL_RECIPIENTS.trim() !== ""
      ) {
        const newEmails = data.EMAIL_RECIPIENTS.split(";").map((email) =>
          email.trim()
        );
        const originalEmails = originalEmailRecipients
          .split(";")
          .map((email) => email.trim());

        const addedEmails = newEmails.filter(
          (email) => !originalEmails.includes(email) && email !== ""
        );

        for (const email of addedEmails) {
          await sendConfirmationEmail({ variables: { input: email } });
        }
      }

      if (editMode) {
        await updateReportSetting({ variables: { input: newData } });
      } else {
        if (data.ISIN && data.PRODUCTNAME) {
          await addReportSetting({ variables: { input: newData } });
        }
      }
      // Refetch the report settings data to update the DataGrid
      await refetchReportSettings();
      setSnackbarSeverity("success");
      setSnackbarMessage(
        `Success! Your changes for ${data.ISIN} have been saved.`
      );
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(`Error saving report setting: ${error.message}`);

      console.error("Error saving report setting:", error.message);
    }
    setSnackbarOpen(true);
    setData((prevData) => ({
      ...prevData,
      EMAIL_FORMAT: "XLS",
      FTP_FORMAT: "XLS",
    }));

    handleDrawerClose();
    setSaving(false);
  };

  const handleDrawerClose = () => {
    setErrors({});
    onClose();
  };
  const handleHistoryChange = (event) => {
    const value = event.target.value;
    // Ensure the value is an integer
    const intValue = parseInt(value, 10);
    if (!isNaN(intValue)) {
      setData({ ...data, HISTORY: intValue });
    }
  };

  // Function to handle changes in the positions dropdown
  const handlePositionsChange = (event) => {
    setData({ ...data, POSITIONS_OPTION: event.target.value });
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ marginTop: 95 }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        classes={{ paper: classes.paper }}
        BackdropProps={{ invisible: true }}
      >
        <div style={{ width: 600, padding: 15 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: "5px 10px 5px 10px",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <Typography variant="h6">
              {editMode ? "Edit Report Setting" : "Add Report Setting"}
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <div style={{ overflowY: "auto", height: gridHeight }}>
            <Grid container spacing={2} style={{ padding: 20 }}>
              <Grid item xs={12}>
                <TextField
                  label="ISIN"
                  value={data.ISIN || ""}
                  onChange={(e) => setData({ ...data, ISIN: e.target.value })}
                  onBlur={handleISINBlur}
                  onKeyPress={handleISINKeyPress}
                  fullWidth
                  disabled={editMode}
                  error={!!errors.ISIN}
                  helperText={errors.ISIN}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Compartment"
                  value={data.PRODUCTNAME || ""}
                  onChange={(e) =>
                    setData({ ...data, PRODUCTNAME: e.target.value })
                  }
                  fullWidth
                  disabled={true}
                  error={!!errors.PRODUCTNAME}
                  helperText={errors.PRODUCTNAME}
                />
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={2} style={{ padding: "1rem" }}>
                  <Typography variant="caption">Run</Typography>
                  <FormGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.RUN_PROFILE?.includes("Official")}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  RUN_PROFILE: e.target.checked
                                    ? data.RUN_PROFILE
                                      ? data.RUN_PROFILE + ",Official"
                                      : "Official"
                                    : data.RUN_PROFILE?.replace(
                                        ",Official",
                                        ""
                                      ) &&
                                      data.RUN_PROFILE?.replace(
                                        "Official,",
                                        ""
                                      ) &&
                                      data.RUN_PROFILE?.replace("Official", ""),
                                })
                              }
                            />
                          }
                          label="Official"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.RUN_PROFILE?.includes("PreRun")}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  RUN_PROFILE: e.target.checked
                                    ? data.RUN_PROFILE
                                      ? data.RUN_PROFILE + ",PreRun"
                                      : "PreRun"
                                    : data.RUN_PROFILE?.replace(
                                        ",PreRun",
                                        ""
                                      ) &&
                                      data.RUN_PROFILE?.replace(
                                        "PreRun,",
                                        ""
                                      ) &&
                                      data.RUN_PROFILE?.replace("PreRun", ""),
                                })
                              }
                            />
                          }
                          label="PreRun"
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Paper>
              </Grid>
              {/* Email Group */}
              <Grid item xs={12}>
                <Paper elevation={2} style={{ padding: "1rem" }}>
                  <Typography variant="caption">Email</Typography>
                  <FormGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.SEND_EMAIL || false}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  SEND_EMAIL: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Send Email"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            value={data.EMAIL_FORMAT}
                            onChange={(e) =>
                              setData({ ...data, EMAIL_FORMAT: e.target.value })
                            }
                          >
                            <MenuItem value={"CSV"}>CSV</MenuItem>
                            <MenuItem value={"XLS"}>XLS</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Email Recipients"
                          value={data.EMAIL_RECIPIENTS || ""}
                          onChange={(e) =>
                            setData({
                              ...data,
                              EMAIL_RECIPIENTS: e.target.value,
                            })
                          }
                          fullWidth
                          error={!!errors.EMAIL_RECIPIENTS}
                          helperText={errors.EMAIL_RECIPIENTS}
                          multiline
                          rows={3}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Paper>
              </Grid>
              {/* FTP Group */}
              <Grid item xs={12}>
                <Paper elevation={2} style={{ padding: "1rem" }}>
                  <Typography variant="caption">FTP</Typography>
                  <FormGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            value={data.SEND_FTP || ""}
                            onChange={(e) =>
                              setData({ ...data, SEND_FTP: e.target.value })
                            }
                          >
                            <MenuItem value={""}>Do Not Send</MenuItem>
                            <MenuItem value={"Kepler"}>Kepler</MenuItem>
                            <MenuItem value={"Silex"}>Silex</MenuItem>
                            <MenuItem value={"Mydesq"}>Mydesq</MenuItem>
                            <MenuItem value={"Ayaltis"}>Ayaltis</MenuItem>
                            <MenuItem value={"AlgorithmTrading"}>AlgorithmTrading</MenuItem>
                            <MenuItem value={"Gestion"}>Gestion</MenuItem>                            
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            value={data.FTP_FORMAT}
                            onChange={(e) =>
                              setData({ ...data, FTP_FORMAT: e.target.value })
                            }
                          >
                            <MenuItem value={"CSV"}>CSV</MenuItem>
                            <MenuItem value={"XLS"}>XLS</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Paper>
              </Grid>
              {/* Combined Row for Schedule and Positions Groups */}
              <Grid item xs={12}>
                <Paper elevation={2} style={{ padding: "1rem" }}>
                  <FormGroup>
                    <Grid container spacing={2}>
                      {/* Schedule Group */}
                      <Grid item xs={6}>
                        <Typography variant="caption">Schedule</Typography>
                        <FormControl fullWidth>
                          <Select
                            value={data.FREQUENCY_OPTION || ""}
                            onChange={handleScheduleChange}
                          >
                            <MenuItem value="Daily">Daily</MenuItem>
                            <MenuItem value="Weekly">Weekly</MenuItem>
                            <MenuItem value="Bi-Monthly">
                              <Tooltip
                                title="On the 15th and the last Business Day of the month"
                                placement="left"
                              >
                                Bi-Monthly
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Quarterly">
                              <Tooltip
                                title="On the 1st Business day in January, April, July, and October"
                                placement="left"
                              >
                                Quarterly
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="Semi-Annually">
                              <Tooltip
                                title="On the 1st Business Day in January and June"
                                placement="left"
                              >
                                Semi-Annually
                              </Tooltip>
                            </MenuItem>
                            <MenuItem value="Annually">Annually</MenuItem>
                          </Select>
                        </FormControl>
                        {/* Dynamic Value Area for Schedule */}
                        {renderDynamicValueArea(data.FREQUENCY_OPTION)}
                      </Grid>

                      {/* Positions Group */}
                      <Grid item xs={6}>
                        <Typography variant="caption">
                          Display Basket Positions
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            value={data.POSITIONS_OPTION || "Full"}
                            onChange={handlePositionsChange}
                          >
                            <MenuItem value="Combined">Combined</MenuItem>
                            <MenuItem value="Single">Single</MenuItem>
                            <MenuItem value="SingleAndCombined">
                              Single + Combined
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={2} style={{ padding: "1rem" }}>
                  <FormGroup>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="History (days)"
                          type="number"
                          value={data.HISTORY || ""}
                          onChange={handleHistoryChange}
                          inputProps={{ min: 0 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.HIDE_NON_ACCUMULATING || false}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  HIDE_NON_ACCUMULATING: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Hide Non-Accumulating Fees"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Report Name Suffix"
                          value={data.REPORT_NAME_SUFFIX || ""}
                          onChange={(e) =>
                            setData({
                              ...data,
                              REPORT_NAME_SUFFIX: e.target.value,
                            })
                          }
                          fullWidth
                          error={!!errors.REPORT_NAME_SUFFIX}
                          helperText={errors.REPORT_NAME_SUFFIX}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                data.INCLUDE_UNSETTLED_POSITIONS || false
                              }
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  INCLUDE_UNSETTLED_POSITIONS: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Include Unsettled Positions"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-start">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.INCLUDE || false}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    INCLUDE: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Active"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color={isDeleteMode ? "secondary" : "primary"}
                  onClick={isDeleteMode ? handleDelete : handleSave}
                  fullWidth
                  disabled={saving}
                >
                  {saving ? (
                    <CircularProgress size={24} />
                  ) : isDeleteMode ? (
                    "Delete"
                  ) : (
                    "Save"
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default EditSettings;
